@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animation-delay-2000 {
    animation-delay: 2s;
  }
  .animation-delay-4000 {
    animation-delay: 4s;
  }

  .video-box-fill {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(193, 192, 209, 0.087) 100%
    );
    box-shadow: 0px 26.3733px 49.9467px rgba(10, 11, 70, 0.25);
    backdrop-filter: blur(10.7133px);
    /* Note: backdrop-filter has minimal browser support */
    box-sizing: border-box;
    border-radius: 40px;
  }
}
